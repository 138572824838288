var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tempData
    ? _c(
        "div",
        { staticClass: "glances-temp-wrapper" },
        _vm._l(_vm.tempData, function (sensor) {
          return _c("div", { key: sensor.label, staticClass: "temp-row" }, [
            _c("p", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm._f("formatLbl")(sensor.label))),
            ]),
            _c("p", { class: `temp range-${sensor.color}` }, [
              _vm._v(_vm._s(_vm._f("formatVal")(sensor.value, sensor.unit))),
            ]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }